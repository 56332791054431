import * as React from "react";
import { Link } from "gatsby";

// import Bridge from "../../components/Bridge.js";
// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";

export default function HighAndDry() {
  return (
    <Layout>
      <Helmet title="High & Dry | Radiohead" />

      <h1>High & Dry</h1>

      <h2>Radiohead</h2>

      <h4>Key: A</h4>

      <h4>Tempo: 94</h4>

      <Link to="/">List</Link>

      <Columns columns="3">
        <Verse>
          <p>Two jumps in a week</p>
          <p>I bet you think that's pretty clever, don't you, boy?</p>
          <p>Flying on your motorcycle</p>
          <p>Watching all the ground beneath you drop</p>
          <p>You'd kill yourself for recognition</p>
          <p>Kill yourself to never, ever stop</p>
          <p>You broke another mirror</p>
          <p>You're turning into something you are not</p>
        </Verse>
        <Chorus>
          <p>Don't leave me high</p>
          <p>Don't leave me dry</p>
          <p>Don't leave me high</p>
          <p>Don't leave me dry</p>
        </Chorus>
        <Verse>
          <p>Drying up in conversation</p>
          <p>You will be the one who cannot talk</p>
          <p>All your insides fall to pieces</p>
          <p>You just sit there wishing you could still make love</p>
          <p>They're the ones who'll hate you</p>
          <p>When you think you've got the world all sussed out</p>
          <p>They're the ones who'll spit at you</p>
          <p>You will be the one screaming out</p>
        </Verse>
        <Chorus>
          <p>Don't leave me high</p>
          <p>Don't leave me dry</p>
          <p>Don't leave me high</p>
          <p>Don't leave me dry</p>
        </Chorus>
        <Verse>
          <p>Oh, it's the best thing that you've ever had</p>
          <p>The best thing that you've ever, ever had</p>
          <p>It's the best thing that you've ever had</p>
          <p>The best thing you have had is gone away</p>
        </Verse>
        <Chorus>
          <p>Don't leave me high</p>
          <p>Don't leave me dry</p>
          <p>Don't leave me high</p>
          <p>Don't leave me dry</p>
          <p>Don't leave me high</p>
          <p>Don't leave me dry</p>
          <p>Don't leave me high</p>
          <p>Don't leave me dry</p>
        </Chorus>
      </Columns>
    </Layout>
  )
}
